import rootScope from "./rootScope";
import countriesCodes from "./countriesCodes";
import detectDevice from "./detectDevice";

    var t = {
        tsMinute: 6e4
    };
    t.tsHour = 60 * t.tsMinute;
    // var n = "bcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789a"
    //     , i = n.split("");
    // return t.num2char = function(e) {
    //         var t = "";
    //         do {
    //             t = n.charAt(e % 60) + t,
    //                 e = Math.floor(e / 60)
    //         } while (e);return t
    //     }
    //     ,
    // t.char2num = function(e) {
    //     for (var t = 0, n = 0; n < e.length; n++)
    //         t = 60 * t + i.indexOf(e.charAt(n));
    //     return t
    // }
    // ,
    // t.latLon2str = function(e) {
    //     var n = Math.floor(100 * (e.lat + 90))
    //         , i = Math.floor(100 * (e.lon + 180));
    //     return t.num2char(n) + "a" + t.num2char(i)
    // }
    // ,
    // t.str2latLon = function(e) {
    //     var n = e.split("a");
    //     return {
    //         lat: t.char2num(n[0]) / 100 - 90,
    //         lon: t.char2num(n[1]) / 100 - 180
    //     }
    // }
    // ,
    t.toggleClass = function(element, t, n) {
        return element.classList[t ? "add" : "remove"](n)
    }
        ,
        // t.emptyFun = function() {}
        // ,
        t.emptyGIF = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==",
        t.isValidLatLonObj = function(object) {
            return object && "object" == typeof object && "lat"in object && "lon"in object && !isNaN(+object.lat) && !isNaN(+object.lon)
        }
        ,
        t.normalizeLatLon = function(e) {
            return parseFloat(e).toFixed(3)
        }
        ,
        t.replaceClass = function(pattern, newclassname, element) {
            // void 0 === n && (n = document.body);
            if ( ! element )
                element = document.body;
            var className = element.className;
            if ( pattern.test(className) )
                element.className = className.replace(pattern, newclassname)
            else
                element.classList.add(newclassname)
        }
        ,
        t.each = function(object, func) {
            for (let key in object)
                func(object[key], key)
        }
        ,
        t.clone = function(object, n) {
            var i;
            if (null === object || "object" != typeof object)
                i = object;
            else if (object instanceof Date)
                (i = new Date).setTime(object.getTime());
            else if (object instanceof Array) {
                i = [];
                for (var s = 0, a = object.length; s < a; s++)
                    i[s] = t.clone(object[s])
            } else if (object instanceof Object)
                for (var r in i = {}, object){
                    // if(!(!object.hasOwnProperty(r) || n && !n.includes(r))){
                    if(object.hasOwnProperty(r) && !(n && !n.includes(r))){
                        i[r] = t.clone(object[r])
                    }

                }
            else
                console.warn("Unable to copy obj! Its type isn't supported.");
            return i
        }
        ,
        // t.compare = function(e, t, n) {
        //     return n || (n = Object.keys(e)),
        //         !n.filter(function(n) {
        //             return e[n] !== t[n]
        //         }).length
        // }
        // ,
        // t.deg2rad = function(e) {
        //     return e * Math.PI / 180
        // }
        // ,
        // t.degToRad = .017453292,
        // t.radToDeg = 57.2957795,

        t.debounce = function(func, timeout, applyNow) {
            let timer;
            return function() {
                let args = arguments
                    , object = this;

                function r() {
                    timer = null;
                    if(!applyNow)
                        func.apply(object, args)
                }
                let apply = applyNow && !timer;
                clearTimeout(timer);
                timer = setTimeout(r, timeout);
                if(apply)
                    func.apply(object, args);
            }
        }
        ,
        t.throttle = function(func, timeout) {
            let changeargs, args, object = this;
            function a() {
                changeargs = false;
                if(args) {
                    throttledFun.apply(object, args);
                    args = false;
                }
            }
            function throttledFun() {
                if (changeargs)
                    args = arguments
                else {
                    // Se ejecuta la función
                    func.apply(object, arguments);
                    // Se programa la ejecucion de 'a' cuando pase el timeout
                    setTimeout(a, timeout);
                    // Si se llama a 'r' mientras no ha pasado el timeout solo se cambiaran los argumentos
                    changeargs = true;
                }
            }

            return throttledFun
        }

        ,
        t.pad = function(number, npad) {
            for (var res = String(number); res.length < (npad || 2); )
                res = "0" + res;
            return res
        }
        ,
        t.template = function(string, params) {
            return string ? string.replace(/\{\{?\s*(.+?)\s*\}?\}/g,
                function(e, n) {
                    return void 0 === params[n] ? "" : params[n]
                }) : ""
        }
        ,
        t.thousands = function (e) {
            return null != e ? e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
        }

        t.wind2obj = function(values) {
            return {
                wind: Math.sqrt(values[0] * values[0] + values[1] * values[1]),
                dir: 10 * Math.floor(18 + 18 * Math.atan2(values[0], values[1]) / Math.PI)
            }
        }
        // ,
        // t.wave2obj = function(e) {
        //     return {
        //         period: Math.sqrt(e[0] * e[0] + e[1] * e[1]),
        //         dir: 10 * Math.floor(18 + 18 * Math.atan2(e[0], e[1]) / Math.PI),
        //         size: e[2]
        //     }
        // }
        // ,
        t.hasDirection = function(e) {
            return "number" == typeof +e.dir && e.dir <= 360 && null != e.dir && e.wind > 0
        }

        t.windDir2html = function(e) {
            return t.hasDirection(e) ? '<div class="iconfont" style="transform: rotate(' + e.dir + "deg); -webkit-transform:rotate(" + e.dir + 'deg);">"</div>' : ""
        }
        // ,
        // t.isNear = function(e, t) {
        //     return Math.abs(+e.lat - +t.lat) < .01 && Math.abs(+e.lon - +t.lon) < .01
        // }
        // ,
        t.bound = function(e, t, n) {
            return Math.max(Math.min(e, n), t)
        }
        ,
        // t.smoothstep = function(e, n, i) {
        //     var s = t.bound((i - e) / (n - e), 0, 1);
        //     return s * s * s * (s * (6 * s - 15) + 10)
        // }
        // ,

        // Pasa la longitud a unidad X de 0 a 1
        t.lonDegToXUnit = function(e) {
            return .5 + .00277777777777777 * e
        }
        ,
        // Pasa la longitud a unidad Y de 0 a 1, siendo 0 la lat 85.051129 y 1 la lat -85.051129
        t.latDegToYUnit = function(e) {
            return t.lat01ToYUnit(.5 - .00555555555555555 * e)
        }
        ,
        t.lat01ToYUnit = function(e) {
            return (Math.PI - Math.log(Math.tan(.5 * (1 - e) * Math.PI))) / (2 * Math.PI)
        }
        ,
        t.interpolatePoint = function(p1, p2, weight){
            return {
                x: p1.x * (1 - weight) + p2.x * weight,
                y: p1.y * (1 - weight) + p2.y * weight
            };
        }
        ,
        t.interpolateLatLonPoint = function (p1, p2, weight) {
            const p1XY = {
                x: t.lonDegToXUnit(p1.lon),
                y: t.latDegToYUnit(p1.lat)
            };
            const p2XY = {
                x: t.lonDegToXUnit(p2.lon),
                y: t.latDegToYUnit(p2.lat)
            };

            const pointXY = t.interpolatePoint( p1XY, p2XY, weight );

            return {
                lon: t.unitXToLonDeg(pointXY.x),
                lat: t.unitYToLatDeg(pointXY.y)
            }

        }
        ,
        t.unitXToLonDeg = function(e) {
            return 360 * (e - .5)
        }
        ,
        t.unitYToLatDeg = function(e) {
            return Math.atan(Math.exp(Math.PI - e * (2 * Math.PI))) / (.5 * Math.PI) * 180 - 90
        }
        ,
        t.nowDeltaTS = 0,
        t.getAdjustedNow = function(e) {
            let n, i = Date.now() - t.nowDeltaTS;
            if (e) {
                if ((n = i - e) < 0)
                    t.nowDeltaTS += n;
                if (n > 1e4)
                    t.nowDeltaTS += n;
            }
            return i;
        }
        ,
        // t.nowDeltaTS = 0,
        // t.getAdjustedNow = function(e) {
        //     var n, i = Date.now() - t.nowDeltaTS;
        //     return e && ((n = i - e) < 0 && (t.nowDeltaTS += n),
        //     n > 1e4 && (t.nowDeltaTS += n)),
        //         i
        // }
        // ,
        t.isPollenCountry = function(country) {
            return rootScope.pollenCountries.includes(country)
        },
        t.isRadarCountry = function(country) {
            return rootScope.radarCountries.includes(country)
        },
        t.isValidLang = function(lang) {
            return rootScope.supportedLanguages.includes(lang)
        },
        t.isValidCountry= function(country) {
            return Object.keys(countriesCodes).includes(country);
        },
        t.defaultLang = function(lang){
            const arrayRegex = /^([a-z]{2})_([A-Z]{2})/.exec(lang);
            if (!arrayRegex || arrayRegex.length === 0)
                return undefined;
            const searchStr = arrayRegex[1] + "_";
            if ( searchStr === "_" )
                return undefined;

            return rootScope.supportedLanguages.find(value => value.startsWith(searchStr));
        }
        ,
        t.joinPath = function(url, path) {
            return url + ("/" === path.charAt(0) ? "" : "/") + path
        }
        ,
        t.addQs = function(acqs, qs) {
            return acqs + (/\?/.test(acqs) ? "&" : "?") + qs
        }
        ,
        // Convierte un objeto con sus propiedades en un string del tipo "prop1=value1&prop2=value2&..."
        t.qs = function(object) {
            var array = [];
            return t.each(object,
                function(value, key) {
                    if ( void 0 !== value )
                        return array.push(key + "=" + value)
                }),
                array.join("&")
        }
        ,
        // t.getFcstUrl = function(t, n, i, s) {
        //     var a = n.lat
        //         , r = n.lon;
        //     return "/forecast/point/" + t + "/" + e.pointForecast + "/" + a + "/" + r + "?source=" + i + (s ? "&" + s : "")
        // }
        // ,
        t.loadScript = function(e) {
            return new Promise(function(t, n) {
                    var i = document.createElement("script");
                    i.type = "text/javascript",
                        i.async = true,
                        i.onload = t,
                        i.onerror = n,
                        document.head.appendChild(i),
                        i.src = e
                }
            )
        };
        t.overlapcanvas = function(dstdata, srcdata, width, height){

            let size = (height * width) << 2;

            for (let pos = 0; pos < size; pos+=4)
            {

                var rR = dstdata.data[pos];
                var rG = dstdata.data[pos + 1];
                var rB = dstdata.data[pos + 2];
                var rA = dstdata.data[pos + 3];

                var fR = srcdata.data[pos];
                var fG = srcdata.data[pos + 1];
                var fB = srcdata.data[pos + 2];
                var fA = srcdata.data[pos + 3];

                if ( rA > 127 && fA > 0) {
                    dstdata.data[pos + 3] = (255 - fA ) * rA / 255 + fA;
                    dstdata.data[pos] =     (rR * rA  * (255 - fA ) + fR * fA * 255 ) / (dstdata.data[pos + 3] * 255 );
                    dstdata.data[pos + 1] = (rG * rA  * (255 - fA ) + fG * fA * 255 ) / (dstdata.data[pos + 3] * 255 );
                    dstdata.data[pos + 2] = (rB * rA  * (255 - fA ) + fB * fA * 255 ) / (dstdata.data[pos + 3] * 255 );
                }

            }

        };

        t.copy2clipboard = function(value) {
            var el = document.createElement("textarea");
            el.value = value;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el)
        };

        t.day2ts = function(day){

            const today = new Date();
            let todayTs = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0, 0)

            const nextDayUTC = new Date(todayTs + day * 24 * 60 * 60 * 1000);
            const nextDay = new Date(nextDayUTC.getUTCFullYear(), nextDayUTC.getUTCMonth(), nextDayUTC.getUTCDate(), 12);

            return nextDay.getTime();
        };

        t.isMobileOrTablet = function(){
            const container = document.querySelector("#map-container");

            const screenWidth = container.offsetWidth;
            const screenHeight = container.offsetHeight;
            const maxDim = Math.max(screenWidth, screenHeight);
            const minDim = Math.min(screenWidth, screenHeight);


            const device = maxDim <= 600 || maxDim <= 960 && minDim <= 600 ? "mobile" :
                maxDim <= 1024 || 1024 === minDim && "ios" === detectDevice.platform ? "tablet" :
                    "desktop";

            return device === "mobile" || device === "tablet";
        };

        t.decodeCoords = function(codedCoords) {
            return {
                lat: (parseInt(codedCoords.substr(0,9))/1000000)-180,
                lon: (parseInt(codedCoords.substr(9))/1000000)-180
            }
        };

        t.encodeCoords = function (coords) {
            return (t.pad(Math.round((coords.lat + 180) * 1000000), 9) +
                (t.pad(Math.round((coords.lon + 180) * 1000000), 9)));
        }
        // ,
        // t.download = function(e, t, n) {
        //     var i = document.createElement("a")
        //         , s = e instanceof Blob ? e : new Blob([e],{
        //         type: t
        //     });
        //     i.style.display = "none",
        //         document.body.appendChild(i),
        //         i.href = window.URL.createObjectURL(s),
        //         i.setAttribute("download", n),
        //         i.click(),
        //         window.URL.revokeObjectURL(i.href),
        //         document.body.removeChild(i)
        // }
        // ,
        // t.isPromise = function(e) {
        //     return "object" == typeof e && "function" == typeof e.then
        // }
        // ,
        export default t;
