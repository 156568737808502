import TimeSliderView from "../ui/TimeSliderView";
import TimeSliderController from "./TimeSliderController";

import OverlaysMenuView from "../ui/OverlaysMenuView"
import OverlaysMenuController from "./OverlaysMenuController";

import LegendRenderView from "../ui/LegendRenderView"
import LegendRenderController from "./LegendRenderController";

import CheckBoxView from "../ui/CheckBoxView";
import CBParticulasController from "./CBParticulasController";
import CBIsobarasController from "./CBIsobarasController";

import SelectView from "../ui/SelectView";
import RangeView from "../ui/RangeView"
import LevelSwitchController from "./LevelSwitchController";
import ModelSwitchController from "./ModelSwitchController";
import MapSwitchController from "./MapSwitchController";
import LangSwitchController from "./LangSwitchController";

import PluginButtonView from "../ui/PluginButtonView";

import MessageView from "../ui/MessageView";
import MessageController from "./MessageController";
import broadcast from "./broadcast";
import PluginButtonController from "./PluginButtonController";
import AQISwitchController from "./AQISwitchController";
import MapDescriptionView from "../ui/MapDescriptionView";
import MapDescriptionController from "./MapDescriptionController";
import PoisSwitchController from "./PoisSwitchController";
import SearchWindowView from "../ui/SearchWindowView";
import SearchWindowController from "./SearchWindowController";
import FullScreenButtonView from "../ui/FullScreenButtonView";
import FullScreenButtonController from "./FullScreenButtonController";
import CaptureButtonView from "../ui/CaptureButtonView";
import CBDisableWebGlController from "./CBDisableWebGlController";
import CaptureButtonController from "./CaptureButtonController";




/* TIME SLIDER */
const timeSliderElement = document.querySelector("#controles_mm");
let timeSliderView = null;
let timeSliderController = null;

broadcast.on("tileLayerOpened", function() {
        if (timeSliderElement && ! timeSliderController) {
            timeSliderView = new TimeSliderView(timeSliderElement);
            timeSliderController = new TimeSliderController(timeSliderView);
        }
    }
);

/* MENUS INICIALES */
const overlaysMenuElement1 = document.querySelector("#menus");
const buttonsMenuElement1 = document.querySelector("#buttonsRight");
if (overlaysMenuElement1 && buttonsMenuElement1) {
    const overlaysMenuView1 = new OverlaysMenuView(overlaysMenuElement1, buttonsMenuElement1);
    const overlaysMenuController = new OverlaysMenuController(overlaysMenuView1);
}



/* MENUS DE CAMBIO DE TIPOS DE MAPA */
const overlaysMenuElement = document.querySelector("#menus");
const buttonsMenuElement = document.querySelector("#menu-mapas");
if (overlaysMenuElement && buttonsMenuElement) {
    const overlaysMenuView = new OverlaysMenuView(overlaysMenuElement, buttonsMenuElement);
    const overlaysMenuController = new OverlaysMenuController(overlaysMenuView);
}

/* LEYENDAS */
const legendRenderElement = document.querySelector("#leyenda");
if (legendRenderElement) {
    const legendRenderView = new LegendRenderView(legendRenderElement);
    const legendRenderController = new LegendRenderController(legendRenderView);
}

/* DESCRIPCIÓN DE MAPAS */
const mapDescriptionElement = document.querySelector("#desc_mapa");
if (mapDescriptionElement) {
    const mapDescriptionView = new MapDescriptionView(mapDescriptionElement);
    const mapDescriptionController = new MapDescriptionController(mapDescriptionView);
}


/* COMBO DE CAMBIO DE NIVEL DE PRESIÓN */
const levelSwitchElement = document.querySelector("#NivelesBar");
if (levelSwitchElement) {
    const levelSwitchView = new RangeView(levelSwitchElement);
    const levelSwitchController = new LevelSwitchController(levelSwitchView);
}

/* COMBO DE CAMBIO DE MODELO */
const modelSwitchElement = document.querySelector("#comboModelos");
if (modelSwitchElement) {
    const modelSwitchView = new SelectView(modelSwitchElement);
    const modelSwitchController = new ModelSwitchController(modelSwitchView);
}

/* COMBO DE CAMBIO DE MAPA DE FONDO */
const mapSwitchElement = document.querySelector("#comboMapas");
if(mapSwitchElement) {
    const mapSwitchView = new SelectView(mapSwitchElement);
    const mapSwitchController = new MapSwitchController(mapSwitchView);
}


/* COMBO DE CAMBIO DE AQI */
const aqiSwitchElement = document.querySelector("#comboAQI");
if(aqiSwitchElement) {
    const aqiSwitchView = new SelectView(aqiSwitchElement);
    const aqiSwitchController = new AQISwitchController(aqiSwitchView);
}

/* COMBO DE CAMBIO DE POIS */
const poisSwitchElement = document.querySelector("#comboPois");
if(poisSwitchElement) {
    const poisSwitchView = new SelectView(poisSwitchElement);
    const aqiSwitchController = new PoisSwitchController(poisSwitchView);
}

// /* COMBO DE CAMBIO DE IDIOMA */
// const langSwitchView = new SelectView("#comboIdiomas");
// const langSwitchController = new LangSwitchController(langSwitchView);


/* CHECKBOX PARA HABILITAR/DESHABILITAR ANIMACIÓN DE PARTÍCULAS */
const cbParticulasElement = document.querySelector('#checkViento');
if (cbParticulasElement) {
    const cbParticulasView = new CheckBoxView(cbParticulasElement);
    const cbParticulasController = new CBParticulasController(cbParticulasView);
}

const cbParticulasElementDesktop = document.querySelector('#checkVientoDesktop');
if (cbParticulasElementDesktop) {
    const cbParticulasViewDesktop = new CheckBoxView(cbParticulasElementDesktop);
    const cbParticulasControllerDesktop = new CBParticulasController(cbParticulasViewDesktop);
}

/* CHECKBOX PARA HABILITAR/DESHABILITAR ISOBARAS */
const cbIsobarasElement = document.querySelector('#checkPresion');
if (cbIsobarasElement) {
    const cbIsobarasView = new CheckBoxView(cbIsobarasElement);
    const cbIsobarasController = new CBIsobarasController(cbIsobarasView);
}

const cbIsobarasElement1 = document.querySelector('#checkPresion1');
if (cbIsobarasElement1) {
    const cbIsobarasView1 = new CheckBoxView(cbIsobarasElement1);
    const cbIsobarasController1 = new CBIsobarasController(cbIsobarasView1);
}

const cbIsobarasElementDesktop = document.querySelector('#checkPresionDesktop');
if (cbIsobarasElementDesktop) {
    const cbIsobarasViewDesktop = new CheckBoxView(cbIsobarasElementDesktop);
    const cbIsobarasControllerDesktop = new CBIsobarasController(cbIsobarasViewDesktop);
}

const cbWebGLDesktop = document.querySelector('#checkWebGlDesktop');
if (cbWebGLDesktop) {
    const cbWebGLViewDesktop = new CheckBoxView(cbWebGLDesktop);
    const cbDisableWebGlControllerDesktop = new CBDisableWebGlController(cbWebGLViewDesktop);
}

// const cbIsobarasElement2 = document.querySelector('#checkPresion2');
// if (cbIsobarasElement2) {
//     const cbIsobarasView2 = new CheckBoxView(cbIsobarasElement2);
//     const cbIsobarasController2 = new CBIsobarasController(cbIsobarasView2);
// }
//
// const cbIsobarasElement3 = document.querySelector('#checkPresion3');
// if (cbIsobarasElement3) {
//     const cbIsobarasView3 = new CheckBoxView(cbIsobarasElement3);
//     const cbIsobarasController3 = new CBIsobarasController(cbIsobarasView3);
// }
//
// const cbIsobarasElement4 = document.querySelector('#checkPresion4');
// if (cbIsobarasElement4) {
//     const cbIsobarasView4 = new CheckBoxView(cbIsobarasElement4);
//     const cbIsobarasController4 = new CBIsobarasController(cbIsobarasView4);
// }

/* MENSAJE DE WARNING */
const msgInfoElement = document.querySelector('#msg_info');
if (msgInfoElement){
    const msgInfoView = new MessageView(msgInfoElement);
    const msgInfoController = new MessageController(msgInfoView);
}


const hurricanesButton =document.querySelector('#hurricanes');
if(hurricanesButton){
    const hurricanesButtonView= new PluginButtonView(hurricanesButton, "hurricanes");
    const hurricanesButtonController = new PluginButtonController(hurricanesButtonView);
}


// const warningButton =document.querySelector('#warnings');
// if(warningButton){
//     const warningButtonView= new PluginButtonView(warningButton, "warnings");
//     const warningButtonController = new PluginButtonController(warningButtonView);
// }


const menuSearch = document.querySelector('#menu-viewersearch');
if(menuSearch){
    const menuSearchView = new SearchWindowView(menuSearch);
    const menuSearchController = new SearchWindowController(menuSearchView);
}


const fullscreenButton = document.querySelector('#full_screen_button');
if(fullscreenButton){
    const fullscreenButtonView = new FullScreenButtonView(fullscreenButton);
    const fullscreenButtonController = new FullScreenButtonController(fullscreenButtonView);
}


const captureButton = document.querySelector('#capture_button');
if(captureButton){
    const captureButtonView = new CaptureButtonView(captureButton);
    const captureButtonController = new CaptureButtonController(captureButtonView);
}
